<template>
  <v-navigation-drawer v-model="open" width="450px" temporary app right>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="text-h5 text-capitalize font-weight-medium pt-3"
        >
          {{ drawerTitle }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="pb-3">
      <keep-alive>
        <component v-bind:is="currentComponent"></component>
      </keep-alive>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data(){
    return {
      formName: ""
    }
  },
  computed: {
    ...mapGetters("ui", ["currentForm", "formEditMode"]),
    currentComponent() {

      const formName = this.currentForm
      if (formName) {
        const component = () => import(`@/components/forms/${formName}`).then((component) => {
          this.formName = component.default.name
        })
        return component
      }
      return null
    },
    drawerTitle() {
      // const entity = this.currentForm.replace(/(-|information|details|form)/g, " ").trim() // file name has to start with entity-*.vue name
      if (this.formEditMode) {
        return `edit ${this.formName}`
      }
      return `add ${this.formName}`
    },
    open: {
      get() {
        return this.$store.getters["ui/drawerIsVisible"]
      },
      set(value) {
        this.$store.commit("ui/toggleDrawer", value)
      }
    }
  }
}
</script>

<style></style>
